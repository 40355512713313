import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Menu from "./Menu"
import styled from "styled-components"

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;
  float: right;
  @media (max-width: 997px) {
    display: flex;
    padding: 0;
  }
`
const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 997px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1vh;
    background-color: rgb(255 255 255 / 100%);
    transition: all 0.3s ease-in;
    top: 89px;
    right: ${props => (props.open ? "-100%" : "0")};
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`
const Hamburger = styled.div`
  background-color: black;
  width: 22px;
  height: 2px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    width: 22px;
    height: 2px;
    background-color: black;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }
  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-7px, 0px)" : "rotate(0deg)"};
    top: -7px;
  }
  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 7px;
  }
`
const Header = ({ pageContext, toggleBackdrop, ...props }) => {
  const { wp, logo } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
      logo: wpMediaItem(title: {eq: "kh-kuva"}) {
        altText
        url: mediaItemUrl
      }
    }
  `)
  const [navbarOpen, setNavbarOpen] = useState(false)
  return (
    <header id="site-header" className="header-footer-group" role="banner">
      <div className="header-inner section-inner">
        <div className="header-titles-wrapper">
          <div className="header-titles">
            <h1 className="site-title">
              <Link to="/">
                <img src="/images/non-sticky-bonukset-logo.png" alt="Non Sticky Bonukset" />
              </Link>
            </h1>
          </div>
          <div className="header-navigation-wrapper">
            <Toggle className="trigger-menu"
              navbarOpen={navbarOpen}
              onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? <Hamburger className="ham" open /> : <Hamburger />}
            </Toggle>
            {navbarOpen ? (
              <Navbox navbarOpen={navbarOpen}
                onClick={() => setNavbarOpen(!navbarOpen)}>
                <Menu />
              </Navbox>
            ) : (
                <Navbox open>
                  <Menu />
                </Navbox>
              )}
          </div>
        </div>

      </div>
    </header>
  )
}

export default Header
